<!--
  This example requires Tailwind CSS v2.0+

  This example requires some changes to your config:

  ```
  // tailwind.config.js
  const colors = require('tailwindcss/colors')

  module.exports = {
    // ...
    theme: {
      extend: {
        colors: {
          'warm-gray': colors.warmGray,
          teal: colors.teal,
        }
      }
    },
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ]
  }
  ```
-->
<template>
  <div class="min-h-screen bg-white" data-aos="fade-in" data-aos-easing="linear" data-aos-duration="1500">
    <main class="overflow-hidden">
      <!-- Header -->
      <div class="bg-warm-gray-50">
        <div class="py-24 lg:py-32">
          <div
            class="relative z-10 max-w-7xl mx-auto pl-4 pr-8 sm:px-6 lg:px-8"
          >
            <h1
              class="text-4xl font-extrabold tracking-tight text-warm-gray-900 sm:text-5xl lg:text-6xl"        data-aos="flip-down"
        data-aos-easing="linear"
            >
              Get in touch.
            </h1>
            <p class="mt-6 text-xl text-warm-gray-500 max-w-3xl">
              Feel free to contact us regards any inquiries you may have regards of the services we offer!
            </p>
                          <p class="mt-4 text-lg text-secondary">
                <span class="font-bold">List of Services:</span>
                <ul>
                  <li>Painting Contracting - Special Finishes - Decorating - Fireproofing - Blowerproof</li>
                </ul>
              </p>
               <p class="mt-4 text-lg text-secondary">
                <span class="font-bold">List of Sectors:</span>
                <ul>
                  <li>Commercial - Residential- Hospitality- Pharma & Healthcare - Public</li>
                </ul>
              </p>
          </div>
        </div>
      </div>

      <!-- Contact section -->
      <section class="relative bg-white" aria-labelledby="contactHeading">
        <div class="absolute w-full h-1/2 bg-warm-gray-50" aria-hidden="true" />
        <!-- Decorative dot pattern -->
        <div class="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <svg
            class="absolute z-0 top-0 right-0 transform -translate-y-16 translate-x-1/2 sm:translate-x-1/4 md:-translate-y-24 lg:-translate-y-72"
            width="404"
            height="384"
            fill="none"
            viewBox="0 0 404 384"
            aria-hidden="true"
          >
            <defs>
              <pattern
                id="64e643ad-2176-4f86-b3d7-f2c5da3b6a6d"
                x="0"
                y="0"
                width="20"
                height="20"
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x="0"
                  y="0"
                  width="4"
                  height="4"
                  class="text-warm-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width="404"
              height="384"
              fill="url(#64e643ad-2176-4f86-b3d7-f2c5da3b6a6d)"
            />
          </svg>
        </div>
        <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div class="relative bg-white rounded-lg shadow-xl border-2 border-secondary" data-aos="fade-up"         data-aos-easing="linear">
            <h2 id="contactHeading" class="sr-only" >Contact us</h2>

            <div class="grid grid-cols-1 lg:grid-cols-3">
              <!-- Contact information -->
              <div
                class="relative overflow-hidden py-10 px-6 bg-gradient-to-b from-teal-500 to-teal-600 sm:px-10 xl:p-12"
              >
                <!-- Decorative angle backgrounds -->
                <div
                  class="absolute inset-0 pointer-events-none sm:hidden"
                  aria-hidden="true"
                >
                  <svg
                    class="absolute inset-0 w-full h-full"
                    width="343"
                    height="388"
                    viewBox="0 0 343 388"
                    fill="none"
                    preserveAspectRatio="xMidYMid slice"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M-99 461.107L608.107-246l707.103 707.107-707.103 707.103L-99 461.107z"
                      fill="url(#linear1)"
                      fill-opacity=".1"
                    />
                    <defs>
                      <linearGradient
                        id="linear1"
                        x1="254.553"
                        y1="107.554"
                        x2="961.66"
                        y2="814.66"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stop-color="#fff" />
                        <stop offset="1" stop-color="#fff" stop-opacity="0" />
                      </linearGradient>
                    </defs>
                  </svg>
                </div>
                <div
                  class="hidden absolute top-0 right-0 bottom-0 w-1/2 pointer-events-none sm:block lg:hidden"
                  aria-hidden="true"
                >
                  <svg
                    class="absolute inset-0 w-full h-full"
                    width="359"
                    height="339"
                    viewBox="0 0 359 339"
                    fill="none"
                    preserveAspectRatio="xMidYMid slice"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M-161 382.107L546.107-325l707.103 707.107-707.103 707.103L-161 382.107z"
                      fill="url(#linear2)"
                      fill-opacity=".1"
                    />
                    <defs>
                      <linearGradient
                        id="linear2"
                        x1="192.553"
                        y1="28.553"
                        x2="899.66"
                        y2="735.66"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stop-color="#fff" />
                        <stop offset="1" stop-color="#fff" stop-opacity="0" />
                      </linearGradient>
                    </defs>
                  </svg>
                </div>
                <div
                  class="hidden absolute top-0 right-0 bottom-0 w-1/2 pointer-events-none lg:block"
                  aria-hidden="true"
                >
                  <svg
                    class="absolute inset-0 w-full h-full"
                    width="160"
                    height="678"
                    viewBox="0 0 160 678"
                    fill="none"
                    preserveAspectRatio="xMidYMid slice"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M-161 679.107L546.107-28l707.103 707.107-707.103 707.103L-161 679.107z"
                      fill="url(#linear3)"
                      fill-opacity=".1"
                    />
                    <defs>
                      <linearGradient
                        id="linear3"
                        x1="192.553"
                        y1="325.553"
                        x2="899.66"
                        y2="1032.66"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stop-color="#fff" />
                        <stop offset="1" stop-color="#fff" stop-opacity="0" />
                      </linearGradient>
                    </defs>
                  </svg>
                </div>
                <!--  -->
                <h3 class="text-lg font-medium text-secondary">Reach Out</h3>
                <p class="mt-6 text-base text-teal-50 max-w-3xl">
               Contacts by Phone or Email, we look forward to speaking to you!
                </p>
              </div>

              <!-- Contact form -->
              <div class="py-10 px-6 sm:px-10 lg:col-span-1 xl:p-12">
                <h3 class="text-lg font-medium text-secondary">
                  Contact information
                </h3>
                <dl class="mt-8 space-y-6">
                  <dt><span class="sr-only">Phone number</span></dt>
                  <dd class="flex text-base text-teal-50">
                    <PhoneIcon
                      class="flex-shrink-0 w-6 h-6 text-teal-200"
                      aria-hidden="true"
                    />
                    <span class="ml-3">+353 86 2590582</span>
                  </dd>
                  <dt><span class="sr-only">Email</span></dt>
                  <dd class="flex text-base text-teal-50">
                    <MailIcon
                      class="flex-shrink-0 w-6 h-6 text-teal-200"
                      aria-hidden="true"
                    />
                    <span class="ml-3">larrykirbybros@gmail.com</span>
                  </dd>
                </dl>
              </div>
              <!-- Contact form -->
              <div class="py-10 px-6 sm:px-10 lg:col-span-1 xl:p-12">
                <h3 class="text-lg font-medium text-secondary">Make Inquiry</h3>
                <a
                  large
                  class="btg bh mt-2"
                  style="color: white"
                  href="mailto:larrykirbybros@gmail.com?subject=Job%20Enquiry"
                  target="_blank"
                >
                  Contact Now
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section aria-labelledby="officesHeading">
        <div class="max-w-7xl mx-auto py-24 px-4 sm:py-32 sm:px-6 lg:px-8">
          <h2
            id="officesHeading"
            class="text-3xl font-extrabold text-warm-gray-900"
          >
            Office Address.
          </h2>
          <p class="mt-6 text-lg text-warm-gray-500 max-w-3xl">
            Kirby Brothers Limited, Unit 14, Finglas Business Centre, Jamestown
            Road, Finglas, Dublin.
          </p>
        </div>
      </section>
      <!-- Contact grid -->
    </main>
    <div class="max-w-7xl mx-auto sm:px-6 lg:px-8 mb-10" data-aos="fade-up"         data-aos-easing="linear">
      <iframe
        class="shadow-xl rounded-lg"
        allowfullscreen
        aria-hidden="false"
        frameborder="0"
        height="500"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2378.9354384521525!2d-6.2978588842546!3d53.39809477853514!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x486712081c6f142d%3A0xa760e2ce66ef7608!2sKirby%20Brothers%20Limited!5e0!3m2!1sen!2sie!4v1616437315004!5m2!1sen!2sie"
        style="border: 0; display: block"
        tabindex="0"
        width="100%"
      />
    </div>
  </div>
</template>

<script>
import { MailIcon, PhoneIcon } from "@heroicons/vue/outline";

const navigation = [
  { name: "Changelog", href: "#" },
  { name: "About", href: "#" },
  { name: "Partners", href: "#" },
  { name: "News", href: "#" },
];

export default {
  components: {
    MailIcon,
    PhoneIcon,
  },
  setup() {
    return {
      navigation,
    };
  },
};
</script>
